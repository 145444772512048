/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
*/
.IsGrey{
  background-color: #bdbdbd38;
}


.fade-enter{
  opacity: 0;
}

.fade-enter-active{
  opacity: 1;
  transition: opacity 300ms ease-out;
}


.fade-leave{
    opacity: 1;
}

.fade-leave-active{
  opacity: 0;
  transition: opacity 300ms ease-out;
}


.left-enter{
    opacity: 0;
    
}

.left-enter-active{
  opacity: 1;
 
  transition: opacity 300ms ease-out;
}

.left-leave{
    opacity: 1;
}

.left-leave-active{
  opacity: 0;
  transition: opacity 300ms ease-out;
}